.link_tag {
  text-decoration: none;
  list-style: none;
}

a.text-white {
  text-decoration: none;
  transition: 0.3s;
}

.footer a.text-white:hover {
  color: #00ff38 !important;
}

.footer-link:hover {
  transform: scale(0.85, 0.85);
  color: white;
}

.footer-terms {
  margin: 10px 100px;
  border-width: 1px;
}

.spacer20 {
  display: inline-block;
  width: 16px;
}

.spacer10 {
  display: inline-block;
  width: 10px;
}

.footer-social {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 10px auto;
  background-color: #151714;
  place-content: center;
}

.footer-link {
  color: white;
  height: 20px;
  width: 20px;
  transition: 0.3s;
}

.terms a {
  text-decoration: none;
  color: white;
}

.footer-privacy {
  align-items: center;
  justify-content: space-between;
}

.row-text {
  align-items: center;
  justify-content: center;
}

.term-link {
  color: #fff;
}
.term-link:hover {
  color: #11ec1c;
}
.privacy-link {
  color: #fff;
}
.privacy-link:hover {
  color: #11ec1c;
}

/* targeting whatsapp-icon */
.footer_container {
  position: relative;
}
/* For default||normal screens */
.whatsapp-icon {
  position: fixed;
  z-index: 10;
  top: 90%;
  left: 95%;
  animation: bounce 1s infinite;
  width: 50px;
  height: 50px;
}
.whatsapp-icon img {
  width: 100%;
  height: 100%;
}
/* Adjusted Bounce Animation for default sized screens*/
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .whatsapp-icon {
    top: 85%;
    left: 92%;
    width: 42px;
    height: 42px;
  }
  /* Adjusted Bounce Animation for Medium Screens (Tablets) */
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-15%); /* Smaller bounce */
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

/* For mobile screens */
@media (max-width: 576px) {
  .whatsapp-icon {
    top: 90%;
    left: 89%;
    width: 35px;
    height: 35px;
  }
  /* Adjusted Bounce Animation for Smaller Screens (Mobile) */
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-10%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
