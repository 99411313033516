.activate {
  border-bottom: 2px solid green;
}

.nav-links {
  transition: 0.3s;
  font-size: 14px;
}

.nav-links:hover {
  border-bottom: 2px solid green;
}

.navbar_toggle_icon {
  background-color: darkgreen;
  color: red;
}

@media (max-width: 980px) {
  .Nav-bar {
    background-color: #f1fff6;
  }
}
