
.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 50; 
  width: 160px; 
  display: none;
}

.dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: black;
  font-size: 12px;
}

.dropdown-item:hover {
  background-color:#38813C;
}

.dropdown-link{
  text-decoration: none;
}


.show {
  display: block;
}
